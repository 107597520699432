import { AppBar, Box, Button, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { PromoCodeV2Alpha2, PromoCodeCheckoutLightweightV1Alpha1 } from '../product/interfaces';
import PromoCard from './PromoCard';
import Footer from '../footer/Footer';

interface Props {
    setPromoCodesCheckout: (promoCodes: PromoCodeCheckoutLightweightV1Alpha1[]) => void;
    promoCodes: PromoCodeV2Alpha2[];
    selectedPromoCodes: PromoCodeV2Alpha2[];
    setSelectedPromoCodes: (promoCodes: PromoCodeV2Alpha2[]) => void;
    goToCart: () => void;
    promoQuantity: number;
    currencyISO: string;
}

export default function PromoSelection({
    promoCodes,
    selectedPromoCodes,
    setSelectedPromoCodes,
    setPromoCodesCheckout,
    goToCart,
    promoQuantity,
    currencyISO
}: Props) {
    const { t } = useTranslation();

    const addPromo = (promoCode: PromoCodeV2Alpha2) => {
        const selectedPromoCodesReturn = [...selectedPromoCodes];
        selectedPromoCodesReturn.push(promoCode);
        setSelectedPromoCodes(selectedPromoCodesReturn);

        const promoCodesCheckout: PromoCodeCheckoutLightweightV1Alpha1[] = selectedPromoCodesReturn.map((promo) => {
            return {
                id: promo.id,
                productVariantUUID: promo.uuid || ''
            };
        });
        setPromoCodesCheckout(promoCodesCheckout);
    };

    return (
        <Box sx={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
            <Box sx={{ p: 2, pb: 0, position: 'relative', flexGrow: 1 }}>
                <Typography variant="h4" gutterBottom>
                    {t('translation.giftcards.giftcards')}
                </Typography>

                {promoCodes
                    .filter((promo) => promo.type !== 0)
                    .map((promo) => (
                        <PromoCard key={promo.id} addPromo={addPromo} promoCode={promo} currencyISO={currencyISO} />
                    ))}
            </Box>
            <AppBar
                position="sticky"
                color="transparent"
                sx={{
                    top: 'auto',
                    bottom: 0,
                    textAlign: 'center',
                    py: 1,
                    boxShadow: 'none',
                    backgroundColor: '#ffffff',
                    px: 2,
                    zIndex: 9999
                }}>
                <Button
                    variant="contained"
                    color="primary"
                    disabled={promoQuantity <= 0}
                    disableElevation
                    sx={{ borderRadius: 20, height: 40, px: 4 }}
                    onClick={() => {
                        goToCart();
                    }}>
                    {t('translation.addonsSelection.goToCartButton')}
                </Button>
                <Footer />
            </AppBar>
        </Box>
    );
}
