import { createTheme } from '@mui/material/styles';
const arcBlue = '#004E31';
const arcOrange = '#D15240';
const arcYellow = '#FFAB1C';
const arcBlack = '#000000';

export default createTheme({
    palette: {
        primary: {
            main: `${arcBlue}`
        },
        secondary: {
            main: `${arcOrange}`
        },
        info: {
            main: `${arcYellow}`
        },
        success: {
            main: `${arcBlack}`,
            dark: '#004030'
        }
    },
    typography: {
        fontFamily: "'Outfit', 'Roboto', 'Helvetica', 'Arial', sans-serif",
        h1: {
            fontWeight: 700,
            fontSize: '2.2em',
            color: arcBlack,
            lineHeight: 1.5,
            fontFamily: "'Outfit', 'Roboto', 'Helvetica', 'Arial', sans-serif"
        },
        h2: {
            fontWeight: 700,
            fontSize: '1.8em',
            color: arcBlack
        },
        h3: {
            fontSize: '1.5em',
            color: arcBlack,
            fontWeight: 600
        },
        h4: {
            fontSize: '20px',
            color: arcBlack,
            fontWeight: 600
        },
        h5: {
            fontSize: '16px',
            fontWeight: 600
        },
        h6: {
            fontWeight: 600,
            fontSize: '14px',
            color: arcBlack,
            lineHeight: 1
        },
        subtitle1: {
            fontSize: '16px',
            fontWeight: 400
        },
        subtitle2: {
            color: arcBlack,
            textTransform: 'none',
            fontWeight: 700,
            fontSize: '16px'
        },
        body1: {
            fontFamily: "'Outfit', 'Roboto', 'Helvetica', 'Arial', sans-serif",
            color: 'rgba(0, 0, 0, 0.87)',
            fontWeight: 400,
            fontSize: '16px'
        },
        body2: {
            fontFamily: "'Outfit', 'Roboto', 'Helvetica', 'Arial', sans-serif",
            color: 'rgba(0, 0, 0, 0.87)',
            fontWeight: 400,
            fontSize: '14px'
        },
        button: {
            fontWeight: 600,
            lineHeight: '1.75em',
            fontSize: '14px'
        },
        caption: {
            fontSize: '12px',
            lineHeight: '20px'
        }
        // caption: {
        //   backgroundColor: arcGrey,
        //   padding: '2px 8px',
        //   borderRadius: '12px'
        // }
    },
    components: {
        MuiTypography: {
            styleOverrides: {
                root: {
                    // fontSize: '1.25em'
                }
            }
        },
        // MuiDateRangePickerDay: {
        //   styleOverrides: {
        //     root: {
        //       '.MuiDateRangePickerDay-day': {
        //         fontSize: '12px !important'
        //       }
        //     }
        //   }
        // },
        // MuiSvgIcon: {
        //   styleOverrides: {
        //     root: {
        //       fontSize: '24px'
        //     }
        //   }
        // },
        MuiStepLabel: {
            styleOverrides: {
                label: {
                    fontSize: '14px'
                }
            }
        },
        MuiOutlinedInput: {
            styleOverrides: {
                notchedOutline: {
                    borderColor: '#bfbfbf'
                }
            }
        },
        MuiButtonBase: {
            styleOverrides: {
                root: {
                    '&.MuiButton-root': {
                        fontSize: '14px',
                        lineHeight: '24px'
                    },
                    '&.MuiIconButton-root': {
                        fontSize: '1.5em'
                    },
                    '&.MuiChip-root': {
                        fontSize: '12px'
                    },
                    '&.MuiPickersDay-root-MuiDateRangePickerDay': {
                        day: {
                            fontSize: '0.8571428571428571em'
                        }
                    },
                    fontSize: '0.8125em',
                    lineHeight: '1.75em'
                }
            }
        },
        MuiBadge: {
            styleOverrides: {
                badge: {
                    fontSize: '12px',
                    lineHeight: '12px'
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: `'Outfit', 'Roboto', 'Helvetica', 'Arial', sans-serif`,
                    boxShadow: 'None'
                }
            },
            defaultProps: {
                disableElevation: true
            }
        },
        MuiInputBase: {
            styleOverrides: {
                root: {
                    fontFamily: `'Outfit', 'Roboto', 'Helvetica', 'Arial', sans-serif`
                }
            }
        },
        MuiDialog: {
            styleOverrides: {
                root: {
                    zIndex: 2147483647
                }
            }
        },
        MuiModal: {
            styleOverrides: {
                root: {
                    zIndex: 2147483647
                }
            }
        },
        MuiTooltip: {
            styleOverrides: {
                popper: {
                    zIndex: 2147483647
                }
            }
        }
    }
});
