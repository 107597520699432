import React, { createContext } from "react";


interface ThemeColorValues {
    themeColor: string;
    setThemeColor: (value: string) => void
}

export const ThemeColorContext = createContext<ThemeColorValues>({
    themeColor: '#004E31',
    setThemeColor: () => {}
})